<template>
  <div class="qr-container">
    <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" />
    <Transition name="simple-fade">
      <div
        v-if="streamRendered && hasManualInput"
        class="qr-manual-input background-white d-flex align-center"
      >
        <v-btn
          icon
          :disabled="videoDevices.length <= 1"
          class="ml-2"
          @click="switchCamera"
        >
          <v-icon>mdi-camera-flip</v-icon>
        </v-btn>
        <v-text-field
          v-model="manualInput"
          class="mx-2"
          flat
          placeholder="Or type manually here..."
          hide-details
          dense
          clearable
          solo
        />
        <v-btn
          color="primary"
          height="38px"
          tile
          :disabled="manualInput.length === 0"
          elevation="0"
          @click="manualDecode"
          >scan</v-btn
        >
      </div>
    </Transition>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
  name: 'BaseAntScanner',
  components: {
    QrcodeStream,
  },
  props: {
    decodeParser: {
      type: Function,
      default: (value) => {
        return value;
      },
    },
    hasManualInput: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => {
    return {
      camera: 'auto',
      manualInput: '',
      streamRendered: false,

      videoDevices: [],
    };
  },
  methods: {
    manualDecode() {
      this.$emit('decodedResult', this.manualInput);
      this.manualInput = '';
      this.camera = 'off';
      this.$nextTick(() => {
        this.camera = 'auto';
      });
    },
    onDecode(result) {
      this.$emit('decodedResult', this.decodeParser(result));

      this.manualInput = '';
      this.camera = 'off';
      this.$nextTick(() => {
        this.camera = 'auto';
      });
    },

    switchCamera() {
      if (this.videoDeviceIndex < this.videoDevices.length - 1) {
        this.videoDeviceIndex++;
      } else {
        this.videoDeviceIndex = 0;
      }
    },

    async onInit(promise) {
      try {
        await promise;
        this.streamRendered = true;

        navigator.mediaDevices.enumerateDevices().then((devices) => {
          this.videoDevices = devices.filter(
            (device) => device.kind === 'videoinput'
          );
        });
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.$store.commit('showNotification', {
            content: 'ERROR: you need to grant camera access permission',
            color: 'error',
          });
        } else if (error.name === 'NotFoundError') {
          this.$store.commit('showNotification', {
            content: 'ERROR: no camera on this device',
            color: 'error',
          });
        } else if (error.name === 'NotSupportedError') {
          this.$store.commit('showNotification', {
            content: 'ERROR: secure context required (HTTPS, localhost)',
            color: 'error',
          });
        } else if (error.name === 'NotReadableError') {
          this.$store.commit('showNotification', {
            content: 'ERROR: is the camera already in use',
            color: 'error',
          });
        } else if (error.name === 'OverconstrainedError') {
          this.$store.commit('showNotification', {
            content: 'ERROR: installed cameras are not suitable',
            color: 'error',
          });
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.$store.commit('showNotification', {
            content: 'ERROR: Stream API is not supported in this browser',
            color: 'error',
          });
        } else if (error.name === 'InsecureContextError') {
          this.$store.commit('showNotification', {
            content:
              'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.',
            color: 'error',
          });
        } else {
          this.$store.commit('showNotification', {
            content: `ERROR: Camera error (${error.name})`,
            color: 'error',
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.qr-container {
  position: relative;

  .qr-manual-input {
    position: absolute;
    bottom: 25px;
    width: 80%;
    left: 10%;
    border-radius: 20px;
    overflow: hidden;
  }
}
</style>
